import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './sessionSlice';
import tabReducer from './tabSlice';
import userReducer from './userSlice'
import enumReducer from './enumsSlice'
import layoutReducer from './layoutSlice'
import interactionReducer from './interactionSlice'
import chatReducer from './focusSlice';
import supportReducer from './supportSlice';
import cacheReducer from './cacheSlice';
import performanceReducer from './performanceSlice';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    tab: tabReducer,
    user: userReducer,
	  enums: enumReducer,
    layout: layoutReducer,
    interaction: interactionReducer,
    focus: chatReducer,
    support: supportReducer,
    cache: cacheReducer,
    performance: performanceReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;