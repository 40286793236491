import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/*
Though these type definitions seem nightmarish, they exist to provide flexibility to the calling code:
-An element can be identified by it's index, it's id, or both.
-A focus element can be a tab or an action.
-Any of these options will be handled by a unified "focus element" function. 
*/

type BlockingState = {
  blocking: boolean;
  reason: 'generating' | 'loading' | 'error' | 'none' | null;
}

interface TabState {
    activeTabIndex: number;
    renderedTabIndex: number;


    activeActionIndex: number;
    maxActionIndex: number;
	activeChatIndex: number;

	//When we're loading a specific report, list, monitor, etc - we don't want the default behavior for session opening to occur, so we need to block it.
	retrieving: string | false;

    // focusElement: null | FocusElement;
    blocking: BlockingState;
}

const initialState: TabState = {
    activeTabIndex: -1,
    activeActionIndex: -1,
    maxActionIndex: 0,
    renderedTabIndex: -1,
	activeChatIndex: -1,
	retrieving: false,
    //focusElement: null,
    blocking: {
      blocking: false,
      reason: null
    }
};

export const tabSlice = createSlice({
	name: 'tab',
	initialState,
	reducers: {
	  setActiveTabIndex: (state, action: PayloadAction<number>) => {
		return {...state, activeTabIndex: action.payload};
	  },
	  setActiveActionIndex: (state, action: PayloadAction<number>) => {
		return {...state, activeActionIndex: action.payload};
	  },
	  setRenderedTabIndex: (state, action: PayloadAction<number>) => {
		return {...state, renderedTabIndex: action.payload};
	  },
	  setActiveChatIndex: (state, action: PayloadAction<number>) => {
		return {...state, activeChatIndex: action.payload};
	  },
	  setMaxActionIndex: (state, action: PayloadAction<number>) => {
		return {...state, maxActionIndex: action.payload};
	  },
	  setBlocking: (state, action: PayloadAction<BlockingState>) => {
		return {...state, blocking: action.payload};
	  },
	  setRetrieving: (state, action: PayloadAction<string | false>) => {
		return {...state, retrieving: action.payload};
	  }
	},
});

export const { setActiveTabIndex, setActiveActionIndex, setActiveChatIndex, setRenderedTabIndex, setMaxActionIndex, setBlocking, setRetrieving } = tabSlice.actions;

export default tabSlice.reducer;