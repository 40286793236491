import type { Config } from 'tailwindcss';
import headlessuiPlugin from '@headlessui/tailwindcss';

const config = {
	darkMode: ['class'],
	content: ['./pages/**/*.{ts,tsx}', './components/**/*.{ts,tsx}', './app/**/*.{ts,tsx}', './src/**/*.{ts,tsx}', './utils/**/*.{ts,tsx}'],
	safelist: [
		...Array.from({ length: 24 }, (_, i) => `grid-cols-${i + 1}`),
		...Array.from({ length: 24 }, (_, i) => `col-span-${i + 1}`),
		'text-icon-sm',
		'text-icon',
		'text-icon-lg',
		//'bg-maven-accent-500'
	],
	prefix: '',
	theme: {
		fontFamily: {
			title: ['Poppins']
		},
		container: {
			center: true,
			padding: '2rem'
		},
		screens: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
			'2xl': '1536px',
			hd: '1900px',
			'2k': '2000px',
			'4k': '3840px'
		},
		extend: {
			containers: {
				'8xl': '88rem',
				'9xl': '96rem',
				'10xl': '104rem'
			},
			fontSize: {
				icon: '24px', // Leave this before the other icon sizes so it shows up first in intelisense
				'icon-sm': '20px',
				'icon-lg': '28px',
				'icon-xl': '32px',
				'icon-2xl': '40px',
				'icon-3xl': '48px'
			},
			colors: {
				'maven-highlight': '#baa2f6',

				// 'maven-primary': {
				// 	'50': '#ecf9f3',
				// 	'100': '#d9f2e6',
				// 	'200': '#bae8d3',
				// 	'300': '#71d0a4',
				// 	'400': '#40bf84',
				// 	'500': '#339969',
				// 	'600': '#297a54',
				// 	'700': '#206042',
				// 	'800': '#143d2a',
				// 	'900': '#0d261a'
				// },
				// 'maven-accent': {
				// 	'50': '#e8e1fc',
				// 	'100': '#dcd1fa',
				// 	'200': '#baa2f6',
				// 	'300': '#9774f1',
				// 	'400': '#7545ed',
				// 	'500': '#5217e8',
				// 	'600': '#4212ba',
				// 	'700': '#350f95',
				// 	'800': '#20075f',
				// 	'900': '#10042f'
				// },
				'maven-error': {
					'50': '#FFEEEE',
					'100': '#FACDCD',
					'200': '#F29B9B',
					'300': '#E66A6A',
					'400': '#D64545',
					'500': '#BA2525',
					'600': '#A61B1B',
					'700': '#911111',
					'800': '#780A0A',
					'900': '#610404'
				},
				'maven-indicator': {
					'50': '#fffaeb',
					'100': '#fcefc7',
					'200': '#f8e3a3',
					'300': '#f9da8b',
					'400': '#f7d070',
					'500': '#e9b949',
					'600': '#c99a2e',
					'700': '#a27c1a',
					'800': '#7c5e10',
					'900': '#513c06'
				},

				'maven-primary': {
					'50': '#F7FDFB',
					'100': '#D4F3E9',
					'200': '#ABE7D5',
					'300': '#81DBC0',
					'400': '#56CEAA',
					'500': '#2CC295',
					'600': '#239B77',
					'700': '#1A7459',
					'800': '#124E3C',
					'900': '#09281E'
				},
				'maven-accent': {
					'50': '#F8F7FA',
					'100': '#DDD7E6',
					'200': '#BCB0CE',
					'300': '#9B89B6',
					'400': '#79619D',
					'500': '#6E47A6',
					'600': '#583985',
					'700': '#422B64',
					'800': '#2C1D43',
					'900': '#160E21'
				},
				// 'maven-error': {
				// 	'50': '#FFFAFA',
				// 	'100': '#FEDEDE',
				// 	'200': '#FDBDBF',
				// 	'300': '#FB9D9F',
				// 	'400': '#FA7C7E',
				// 	'500': '#F95B5E',
				// 	'600': '#C8494C',
				// 	'700': '#953738',
				// 	'800': '#652526',
				// 	'900': '#341314'
				// },
				// 'maven-indicator': {
				// 	'50': '#FFFFF9',
				// 	'100': '#FFF8D5',
				// 	'200': '#FFFIAD',
				// 	'300': '#FFEA84',
				// 	'400': '#FFE25A',
				// 	'500': '#FFDB31',
				// 	'600': '#C8D027',
				// 	'700': '#99831D',
				// 	'800': '#652526',
				// 	'900': '#332C0A'
				// }
			},

			fontFamily: {
				poppins: ['Poppins', 'sans-serif'],
				nunito: ['Nunito', 'sans-serif'],
				'open-sans': ['Open Sans', 'sans-serif']
			},
			keyframes: {
				'bg-opacity-animation': {
					'0%': { 'background-opacity': '0.5' },
					'100%': { 'background-opacity': '1' }
				},
				'cursor-flash': {
					'0%': { opacity: '1' },
					'50%': { opacity: '0' },
					'100%': { opacity: '1' }
				},
				'accordion-down': {
					from: { height: '0' },
					to: { height: 'var(--radix-accordion-content-height)' }
				},
				'accordion-up': {
					from: { height: 'var(--radix-accordion-content-height)' },
					to: { height: '0' }
				},
				'fade-in': {
					from: {
						opacity: '0'
					},
					to: {
						opacity: '1'
					}
				},
				marquee: {
					'100%': {
						transform: 'translateY(-50%)'
					}
				},
				'spin-reverse': {
					to: {
						transform: 'rotate(-360deg)'
					}
				},
				'loading-slider': {
					'0%': { backgroundPosition: '-150% 0, -150% 0' },
					'66%': { backgroundPosition: '250% 0, -150% 0' },
					'100%': { backgroundPosition: '250% 0, 250% 0' },
				},
			},
			animation: {
				'accordion-down': 'accordion-down 0.2s ease-out',
				'accordion-up': 'accordion-up 0.2s ease-out',
				'cursor-flash': 'cursor-flash 1s infinite',
				'fade-in': 'fade-in 0.5s linear forwards',
				marquee: 'marquee var(--marquee-duration) linear infinite',
				'spin-slow': 'spin 4s linear infinite',
				'spin-slower': 'spin 6s linear infinite',
				'spin-reverse': 'spin-reverse 1s linear infinite',
				'spin-reverse-slow': 'spin-reverse 4s linear infinite',
				'spin-reverse-slower': 'spin-reverse 6s linear infinite',
				'bg-opacity-animation': 'bg-opacity-animation 1s ease-in-out',
				'loading-slider': 'loading-slider 5s infinite'
			}
		}
	},
	plugins: [
		require('@tailwindcss/container-queries'),
		require('@tailwindcss/forms'),
		require('tailwindcss-animate'),
		require('tailwind-scrollbar'),
		headlessuiPlugin
	]
} satisfies Config;

export default config;
