import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EnumsState {
  loaded: boolean;
  request: boolean;
  requested: boolean;
  enums: any;
}

const initialState: EnumsState = {
  loaded: false,
  request: false,
  requested: false,
  enums: {},
};

export const enumsSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setEnums: (state, action: PayloadAction<any>) => {
      //state.sessionData = action.payload;
	  return {...state, enums: action.payload};
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      return {...state, loaded: action.payload};
    },
    setRequest: (state, action: PayloadAction<boolean>) => {
      return {...state, request: action.payload};
    },
    setRequested: (state, action: PayloadAction<boolean>) => {
      return {...state, requested: action.payload};
    },
  },
});

export const { setEnums, setLoaded, setRequest, setRequested } = enumsSlice.actions;

export default enumsSlice.reducer;