import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CacheState {
	pages: Record<string, any>;
	search: Record<string, any>;
	pagination: Record<string, any>;
	flags: Record<string, any>;
	buffering: string[];
}

const initialState: CacheState = {
	pages: {},
	search: {},
	pagination: {},
	flags: {},
	buffering: []
};

export const cacheSlice = createSlice({
	name: 'cache',
	initialState,
	reducers: {
		setPages: (state, action: PayloadAction<any>) => {
			return { ...state, pages: action.payload };
		},
		addPages: (state, action: PayloadAction<any>) => {
			//console.log('addPages', state, action.payload);
			return { ...state, pages: {
				...state.pages,
				[action.payload.key]: action.payload.value
			}};
		},

		setSearch: (state, action: PayloadAction<any>) => {
			return { ...state, search: action.payload };
		},
		addSearch: (state, action: PayloadAction<any>) => {
			return { ...state, search: {
				...state.search,
				[action.payload.key]: action.payload.value
			}};
		},

		setPagination: (state, action: PayloadAction<any>) => {
			return { ...state, pagination: action.payload };
		},
		addPagination: (state, action: PayloadAction<any>) => {
			return { ...state, pagination: {
				...state.pagination,
				[action.payload.key]: action.payload.value
			}};
		},

		setFlags: (state, action: PayloadAction<any>) => {
			return { ...state, flags: action.payload };
		},
		addFlags: (state, action: PayloadAction<any>) => {
			return { ...state, flags: {
				...state.flags,
				[action.payload.key]: action.payload.value
			}};
		},

		setBuffering: (state, action: PayloadAction<string[]>) => {
			return { ...state, buffering: action.payload };
		},
		addBuffering: (state, action: PayloadAction<string>) => {
			return { ...state, buffering: [...state.buffering, action.payload] };
		},
		removeBuffering: (state, action: PayloadAction<string>) => {
			return { ...state, buffering: state.buffering.filter((key) => key !== action.payload) };
		}
	},
});

export const { setPages, addPages, setSearch, addSearch, addPagination, setPagination, setBuffering, addBuffering, removeBuffering, setFlags, addFlags } = cacheSlice.actions;
export default cacheSlice.reducer;