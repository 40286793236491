import { User } from '@propelauth/react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState
{
	user: User | null;
	token: string;
}

const initialState: UserState = {
	user: null,
	token: ''
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) =>
		{
			//state.user = action.payload;
			return { ...state, user: action.payload };
		},
		setToken: (state, action: PayloadAction<string>) =>
		{
			//state.token = action.payload;
			return { ...state, token: action.payload };
		}
	}
});

export const { setUser, setToken } = userSlice.actions;
export default userSlice.reducer;
