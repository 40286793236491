import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TourProgressEntry
{
	tour_id: string;
	step: number;
	started_at: Date | string | null;
	completed_at: Date | string | null;
}

interface TourProgress
{
	[tour_id: string]: TourProgressEntry;
}

interface SupportArticle
{
	id: string;
	title: string;
	category: string;
}

interface TourArticleMappingEntry
{
	tour_id: string;
	article_id: string;
}

interface SupportState
{
	tourProgress: TourProgress | null;
	supportArticles: SupportArticle[];
	tourArticleMapping: TourArticleMappingEntry[];
	overlayTab: 'tours' | 'support' | 'keybinds';
	isNew?: boolean;
	flow: any;
}

const initialState: SupportState = {
	overlayTab: 'tours',
	isNew: undefined,
	tourProgress: null,
	supportArticles: [],
	tourArticleMapping: [],
	flow: false
};

export const supportSlice = createSlice({
	name: 'support',
	initialState,
	reducers: {
		//Set overlay tab
		setOverlayTab: (state, action: PayloadAction<'tours' | 'support' | 'keybinds'>) =>
		{
			return { ...state, overlayTab: action.payload };
		},

		//Set user flag
		setIsNew: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, isNew: action.payload };
		},

		//Basic reducers
		setTourProgress: (state, action: PayloadAction<TourProgress>) =>
		{
			return { ...state, tourProgress: action.payload };
		},
		setSupportArticles: (state, action: PayloadAction<SupportArticle[]>) =>
		{
			return { ...state, supportArticles: action.payload };
		},
		setTourArticleMapping: (state, action: PayloadAction<TourArticleMappingEntry[]>) =>
		{
			return { ...state, tourArticleMapping: action.payload };
		},

		//Update tour progress
		updateTourProgress: (state, action: PayloadAction<TourProgressEntry>) =>
		{
			const { tour_id, step, started_at, completed_at } = action.payload;
			let newState = { ...state }; // Shallow copy of the state
			let tourProgress = { ...newState.tourProgress }; // Deep copy of the tourProgress object

			if (!tourProgress[tour_id])
			{
				// If the tour_id doesn't exist, create a new entry
				tourProgress[tour_id] = { ...action.payload };
			}
			else
			{
				// If it exists, update the necessary fields
				tourProgress[tour_id] = {
					...tourProgress[tour_id],
					step,
					started_at: started_at ? started_at : tourProgress[tour_id].started_at,
					completed_at: completed_at //? completed_at : tourProgress[tour_id].completed_at,
				};
				console.log(tourProgress[tour_id]);
			}

			// Update the tourProgress in the newState object
			newState.tourProgress = tourProgress;

			// Return the newState object
			return newState;
		},

		//Guided flows
		setFlow: (state, action: PayloadAction<any>) =>
		{
			return { ...state, flow: action.payload };
		},

		updateFlow: (state, action: PayloadAction<any>) =>
		{
			return { ...state,
				flow: {
					...state.flow,
					[action.payload.key]: action.payload.value
				}
			};
		}
	}
});

export const { setOverlayTab, setIsNew, setTourProgress, setSupportArticles, setTourArticleMapping, updateTourProgress, setFlow, updateFlow } = supportSlice.actions;
export default supportSlice.reducer;
